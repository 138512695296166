<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/coupons">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form id="add-company-form" class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الكود</label>
                    <input id="validationTooltip01" v-model="tooltipform.code" type="text" class="form-control"
                      placeholder="الكود" :class="{
                        'is-invalid': submitform && $v.tooltipform.code.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.code.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.code.required">
                        الكود مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">نسبة الخصم</label>
                    <div class="input-group">
                      <input id="validationTooltip01" v-model="tooltipform.ratio" type="number" class="form-control"
                        placeholder="نسبة الخصم" :class="{
                          'is-invalid': submitform && $v.tooltipform.ratio.$error,
                        }" />
                        <div class="input-group-prepend">
                          <span id="validationTooltipUsernamePrepend" class="input-group-text">%</span>
                        </div>
                    </div>
                    <div v-if="submitform && $v.tooltipform.ratio.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.ratio.required">
                        نسبة الخصم مطلوبة.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">مرات الاستخدام</label>
                    <input id="validationTooltip01" v-model="tooltipform.times_of_use" type="number" class="form-control"
                      placeholder="مرات الاستخدام" :class="{
                        'is-invalid': submitform && $v.tooltipform.times_of_use.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.times_of_use.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.times_of_use.required">
                        مرات الاستخدام مطلوبة.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الصلاحية</label>
                    <input id="validationTooltip01" v-model="tooltipform.validity" type="date" class="form-control"
                      placeholder="الصلاحية" :class="{
                        'is-invalid': submitform && $v.tooltipform.validity.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.validity.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.validity.required">
                        الصلاحية مطلوبة.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label mb-3 d-flex">الحالة :</label>
                    <div class="form-check form-check-inline">
                      <input v-model="tooltipform.status" type="radio" id="customRadioInline1" name="status" value="1"
                        class="form-check-input" />
                      <label class="form-check-label" for="customRadioInline1">مفعل</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input v-model="tooltipform.status" type="radio" id="customRadioInline2" name="status" value="0"
                        class="form-check-input" />
                      <label class="form-check-label" for="customRadioInline2">غير مفعل</label>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary float-right" type="submit">
                {{ $t('add') }}
                <i class="mdi mdi-plus-thick"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Add coupon",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      title: "Add coupon",
      tooltipform: {
        code: "",
        ratio: "",
        times_of_use: "",
        validity: "",
        status: 1,
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    tooltipform: {
      code: { required },
      ratio: { required },
      times_of_use: { required },
      validity: { required }
    },
  },
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    Jquery() {
      $(document).on('submit', '#add-company-form', function () {
        if (!that.$v.$anyError) {
          $(this).find(":submit").attr('disabled', 'disabled');
          let form = new FormData();
          form.append('code', that.tooltipform.code);
          form.append('ratio', that.tooltipform.ratio);
          form.append('times_of_use', that.tooltipform.times_of_use);
          form.append('validity', that.tooltipform.validity);
          form.append('status', that.tooltipform.status);
          form.append('lang', that.$i18n.locale);
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          };
          var url = `${that.$appUrl}/api/admin/coupons/store`;
          that.axios.post(url, form, { headers })
            .then(response => {
              if (response.data.status == 1) {
                that.$toast.open({
                  message: response.data.message,
                  type: 'success',
                  position: 'top-left',
                  duration: 2500
                });
                setTimeout(() => {
                  that.$router.push(`/coupons`);
                }, 1000);
              } else {
                $(this).find(":submit").prop('disabled', false);
                that.$toast.open({
                  message: response.data.message,
                  type: 'error',
                  position: 'top-left',
                  duration: 2500
                });
              }
            });
        }
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
  }
};
</script>
<style lang="scss" scoped>
input[type="number"],
input[type="date"],
input[type="text"] {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0;
  min-height: 2.375rem;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
.input-group-text {
  border-radius: 0 !important;
  min-height: 2.375rem;
}
</style>